import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
 // Updated import
// Correct import
import './App.css';
import Header from './components/Header';
import Cards from './components/Cards';
import Dashboard from './components/Dashboard';
import Historical from './Historical Components/Historical';
import HDImpact from './HD Impact/HDImpactDashboard';
import CitizenImpact from './Citizen Impact/CitizenImpactDashboard';
import Map2 from './Map2/SchemeDashboard';
import ClientFilter from './ClientFilter/ClientDashboard';
import AllFilters from './AllFilters/AllFilterDashboard';
import MSMEImpact from './MSME Impact/MsmeImpactDashboard';
import GoogleLogin from './LoginCredential/GoogleLogin';

// Your code continues...


const AUTHORIZED_USERS = [
  "rushikesh.darandale@haqdarshak.com", "shubham.sabale@haqdarshak.com",
  "shweta.chaudhury@haqdarshak.com", "vedant.manore@haqdarshak.com",
  "suraj.urvashi@haqdarshak.com", "prabhu.kunwar@haqdarshak.com",
  "piyusha.verma@haqdarshak.com", "nakul.purohit@haqdarshak.com",
  "isha.sharma@haqdarshak.com", "diva.mukherji@haqdarshak.com",
  "arya.raje@haqdarshak.com", "pratiksha.bhosale@haqdarshak.com",
  "reet.singh@haqdarshak.com","prabhat.sharma@haqdarshak.com",
  "savita.muley@haqdarshak.com","rasika.kaware@haqdarshak.com","arjuna.tiwari@haqdarshak.com",
  "neeraja.sajan@haqdarshak.com"
];

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const storedLogin = localStorage.getItem('isLoggedIn');
    const storedAuthorization = localStorage.getItem('isAuthorized') === 'true';

    setIsLoggedIn(storedLogin === 'true');
    setIsAuthorized(storedAuthorization);

    if (storedLogin === 'true') {
      setupAutoLogoutTimers();
    }
  }, []);

  const setupAutoLogoutTimers = () => {
    // Logout after 2 hours of login
    setTimeout(() => {
      
      handleLogout();
    }, 2 * 60 * 60 * 1000); // 2 hours

    // Reset inactivity timer on user activity
    const handleActivity = () => {
      clearTimeout(window.inactivityTimer);
      window.inactivityTimer = setTimeout(() => {
        
        handleLogout();
      }, 15 * 60 * 1000); // 15 minutes
    };

    // Attach activity listeners
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    // Clean up activity listeners on unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      clearTimeout(window.inactivityTimer);
    };
  };

  const handleGoogleSuccess = (response) => {
    try {
      const decoded = jwtDecode(response.credential);
      console.log('Login successful:', decoded);

      const userEmail = decoded.email;
      setIsLoggedIn(true);
      setUserDetails(decoded);

      const authorized = AUTHORIZED_USERS.includes(userEmail);
      setIsAuthorized(authorized);

      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('isAuthorized', authorized ? 'true' : 'false');

      setupAutoLogoutTimers();
    } catch (error) {
      console.error('Failed to decode Google credential:', error);
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Login failed:', error);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserDetails(null);
    setIsAuthorized(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('isAuthorized');
    sessionStorage.clear();

    // Clear inactivity timer
    if (window.inactivityTimer) clearTimeout(window.inactivityTimer);
  };

  return (
    <GoogleOAuthProvider clientId="555335856204-5pnk4cv225loruelbffkhjft659te6dq.apps.googleusercontent.com">
      <Router>
        <div className="app">
          {!isLoggedIn ? (
            <GoogleLogin
              onLoginSuccess={handleGoogleSuccess}
              onLoginFailure={handleGoogleFailure}
            />
          ) : (
            <>
              <Header handleLogout={handleLogout} />
              <Routes>
                <Route
                  path="/"
                  element={
                    isAuthorized ? (
                      <>
                        <Cards />
                        <Dashboard />
                      </>
                    ) : (
                      <div>
                        <h2>Access Denied</h2>
                        <p>You do not have access to this dashboard. Please log out.</p>
                      </div>
                    )
                  }
                />
                <Route path="/historical" element={isAuthorized ? <Historical /> : <p>Access Denied</p>} />
                <Route path="/hd-impact" element={isAuthorized ? <HDImpact /> : <p>Access Denied</p>} />
                <Route path="/citizen-impact" element={isAuthorized ? <CitizenImpact /> : <p>Access Denied</p>} />
                <Route path="/map2" element={isAuthorized ? <Map2 /> : <p>Access Denied</p>} />
                <Route path="/clientfilter" element={isAuthorized ? <ClientFilter /> : <p>Access Denied</p>} />
                <Route path="/allfilters" element={isAuthorized ? <AllFilters /> : <p>Access Denied</p>} />
                <Route path="/msme-impact" element={isAuthorized ? <MSMEImpact /> : <p>Access Denied</p>} />
              </Routes>
            </>
          )}
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
